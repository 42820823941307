<template>
  <div class="creek-schedule-occurrence">
    <CreekShow 
      :show="occurrence.time.show" 
      :start-date="occurrence.startDate"
      :end-date="occurrence.endDate"
    />
    <!-- 
    <CreekShow 
      v-for="(occurrence, index) in occurrence.group"
      :is-from-group="true"
      :show="occurrence.time.show" 
      :start-date="occurrence.startDate"
      :end-date="occurrence.endDate"
      :key="index"
    /> 
    -->
  </div>
</template>

<script>

import CreekShow from '@creek-website-toolkit/components/cards/ShowCard';

export default {
  name: 'CreekScheduleOccurrence',
  props: {
    occurrence: {
      type: Object
    }
  },
  data(){
    return {}
  },
  components: {
    CreekShow
  },
  methods: {

  }
}
</script>