<template>
  <div class="SchedulePage">
    <CreekSchedule
      :weekday-number="weekdayNumber" 
      :page-name="pageName"
    />
  </div>
</template>

<script type="text/javascript">

import CreekSchedule from '@creek-website-toolkit/components/schedule/Schedule';

export default {
  name: 'SchedulePage',
  components: {
    CreekSchedule,
  },
  props: {
    weekdayNumber: {
      type: [Number, String],
    },
    pageName: {
      type: String,
      default: null,
    },
  },
  data () {
    return {

    }
  },
  computed: {

  },
  methods: {

  },
  created () {

    X('SchedulePage')

  },
}

</script>
