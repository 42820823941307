<template>
  <div class="creek-schedule-nav">
    <ul class="creek-schedule-nav-weekdays">
      <li 
        v-for="(weekday, weekdayNum) in weekdays" 
        class="creek-schedule-nav-weekday"
        :class="{ 'creek-selected': weekdayNum == weekdayNumber }"
      >
        <router-link 
          :to="'/schedule/weekday/' + weekdayNum" 
          v-text="weekday"
        />
      </li>
    </ul>
    <div 
      class="creek-schedule-nav-all"
      :class="{ 'creek-selected': pageName == 'all' }"
    >
      <router-link 
        to="/schedule/all" 
        v-text="lang.schedule.all" 
      />
    </div>
  </div>
</template>

<script type="text/javascript">

import lang from '@creek-website-toolkit/lang/eng'

export default {

  name: 'CreekScheduleNav',

  props: {

    weekdayNumber: {
      type: [String, Number],
    },
    pageName: {
      type: String,
      default: null,
    },

  },

  components: {
  },

  data () {

    return {

      weekdays: lang.schedule.weekdays,

      lang,

    }

  },

  computed: {

  },
  methods: {

  },
  created () {

  },
}

</script>
