<template>
  <div class="creek-show" :class="['creek-show-' + show.name]">
    <div class="grouped-show-label" v-if="isFromGroup">
      ---
      <!--  -->
    </div>
    <div class="creek-show-inner">
      <div 
        v-if="startDate && endDate"
        class="creek-time-container" 
      >
        <div class="creek-time">
          <span 
            class="creek-start" 
            v-html="formatTime(startDate)"
          ></span>
          <span class="creek-divider">&mdash;</span>
          <span 
            class="creek-end" 
            v-html="formatTime(endDate)"
          ></span>
        </div>
      </div>
      <div class="creek-info-container">
        <div class="creek-image-container" v-if="show.image">
          <img :src="show.image.url" />
        </div>
        <a 
          class="creek-show-title" 
          v-text="show.title"
          @click="navigateTo('shows/' + show.name)" 
        ></a>
        <div 
          class="creek-summary" 
          v-text="show.summary"
        ></div>
        <div class="creek-authors-container">
          <ul>
            <li v-for="author in show.authors">
              <a 
                class="creek-author-link" 
                href="#" 
                :href="getProfileURL(author)" 
                v-text="author.display_name"
              ></a>
            </li>
          </ul>
        </div>
        <!--
        <div class="tags">
          <div class="tags-container">
            <ul>
              <li v-for="tag in show.tags">
                <a href="#" class="creek-category-link" v-text="tag.title"></a>
              </li>
            </ul>
          </div>
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">

import { lightFormat } from 'date-fns'

import navigate from '@creek-website-toolkit/lib/navigate.js'
import time from '@creek-website-toolkit/lib/time.js'

export default {
  name: 'CreekShow',
  props: {
    show: {
      type: Object
    },
    startDate: {
      type: Date
    },
    endDate: {
      type: Date
    },
    isFromGroup: {
      type: Boolean
    }
  },
  data () { 
    return {
    } 
  },
  computed: {

  },
  methods: {
    getShowURL(show){
      return '/shows/' + show.name
    },
    getProfileURL(author){
      return '/shows/' + author.name
    },
    formatTime: time.format,
    navigateTo: navigate.to,
  },
  created() {

    // console.log(this.dataShow);

  }
}

</script>