<template>
  <div class="creek-schedule">
    <CreekScheduleNav
      :page-name="pageName" 
      :weekday-number="weekdayNumberOrToday"
    />
      <!-- v-if="pageName == 'all'" -->
    <div 
      class="creek-find-container" 
    >
      <input 
        type="text" 
        v-model="stringToFind" 
        placeholder="Find" 
      />
    </div>
    <div 
      v-if="isScheduleReadyToRender" 
      class="creek-occurrences"
    >
      <CreekScheduleOccurrence
        :occurrence="occurrence" 
        v-for="(occurrence, index) in occurrencesFiltered" 
        :key="'occurrence-' + occurrence.time.id" 
      />
    </div>
    <div 
      v-if="!isScheduleReadyToRender" 
      class="creek-occurrences" 
    >
      <span v-text="lang.loading"></span>
    </div>
  </div>
</template>

<script type="text/javascript">

import _ from 'lodash'
import lang from '@creek-website-toolkit/lang/eng'

import { 
  lightFormat, 
  parseISO, 
  getDay, 
  startOfWeek, 
  endOfWeek, 
  startOfMonth, 
  addWeeks, 
  addSeconds,
} from 'date-fns'

import { RRule, RRuleSet, rrulestr } from 'rrule'

import CreekStudio from '@creek-website-toolkit/CreekStudio'

import CreekScheduleOccurrence from '@creek-website-toolkit/components/schedule/ScheduleOccurrence'
import CreekScheduleNav from '@creek-website-toolkit/components/schedule/ScheduleNav'

export default {

  name: 'CreekSchedule',

  props: {
    weekdayNumber: {
      type: [Number, String],
    },
    pageName: {
      type: String,
      default: null,
    },
  },

  components: {
    CreekScheduleOccurrence,
    CreekScheduleNav,
  },

  data () {

    return {

      times: null,

      stringToFind: '',

      occurrencesWithinThisWeek: [],
      occurrencesWithinFiveWeekSpan: [],
      occurrencesGrouped: [],

      isScheduleReadyToRender: false,

      lang,

    }

  },

  computed: {
    weekdayNumberOrToday (){
      if(this.weekdayNumber) {
        return this.weekdayNumber;
      }
      else if(this.pageName != 'all'){
        return this.getWeekdayNumberForToday()
      }
      else if(this.pageName == 'all'){
        return null
      }
      else if(this.stringToFind){
        // X(this.stringToFind)
        return null
      }
    },
    occurrencesFiltered () {
      
      let occurrencesFiltered = []

      if(!this.occurrencesWithinThisWeek) {
        return []
      }
      
      for(let o in this.occurrencesWithinThisWeek){
        
        const occurrence = this.occurrencesWithinThisWeek[o]
        let shouldInclude = false

        // Show all for weekday if displaying weekday.
        if(
          getDay(occurrence.startDate) == this.weekdayNumberOrToday
        ){
          shouldInclude = true
        }
        
        // Show all by default if page is "all".
        if(this.pageName == 'all'){
          shouldInclude = true
        }
        
        if(
          this.stringToFind
          && this.stringToFind != ''
        ){
          shouldInclude = true
        }

        // If using Find, then filter by stringToFind.
        if(
          this.stringToFind
          && this.stringToFind != ''
          && occurrence.time.show 
          && !occurrence.time.show.title
            .toLowerCase().includes(this.stringToFind.toLowerCase())
        ){
          shouldInclude = false
        }

        if(shouldInclude){
          occurrencesFiltered.push(occurrence)
        }

      }
      return occurrencesFiltered
    }
  },
  methods: {

    async fetchData () {
      
      this.times = await CreekStudio.get('times/not-expired')
      
      // X(this.times)

    },

    getWeekdayNumberForDate(date){

      return getDay(parseISO(date))

    },

    getWeekdayNumberForToday() {

      // Note: Sunday == 0

      const weekdayNumber = getDay(new Date())

      return weekdayNumber

    },

    buildOccurrence(occurrenceDate, time, rruleInstance){

      let occurrence = {}

      occurrence.time = time
      occurrence.rruleInstance = rruleInstance
      
      // X(occurrenceDate)

      occurrence.startDate = occurrenceDate
      occurrence.duration = time.duration
      occurrence.endDate = addSeconds(occurrenceDate, time.duration)


      // X(occurrence.startDate)

      occurrence.startTimeString = lightFormat(occurrence.startDate, 'HH:mm')
      occurrence.weekdayNum = getDay(occurrence.startDate)
      occurrence.group = []

      return occurrence

    },

    processTimes(){

      this.times = _.orderBy(this.times, ['start'], ['asc'])


      for (let t in this.times){

        const time = this.times[t]

        const timeRRULE = new rrulestr(time.rrule)

        const date = new Date()

        // X('rrule')
        // X(time.rrule)

        // X('start and end')
        // X(startOfWeek(date))
        // X(endOfWeek(date))


        const occurrenceDatesWithinThisWeek = timeRRULE.between(
          startOfWeek(date), endOfWeek(date)
        )

        // X(occurrenceDatesWithinThisWeek)

        const occurrenceDatesWithinFiveWeekSpan = timeRRULE.between(
          startOfMonth(date), addWeeks(startOfMonth(date), 5)
        )

        for(let occurrenceDate of occurrenceDatesWithinThisWeek){
          // X(occurrenceDate)
          // X('woop')
          this.occurrencesWithinThisWeek.push(
            this.buildOccurrence(occurrenceDate, time, timeRRULE)
          )
        }

        for(let occurrenceDateISO of occurrenceDatesWithinFiveWeekSpan){
          this.occurrencesWithinFiveWeekSpan.push(
            this.buildOccurrence(occurrenceDateISO, time, timeRRULE)
          )
        }

      }

    },

    groupOverlappingOccurrences(){

      // Loop over all occurrencesWithinThisWeek
      for (let o1 in this.occurrencesWithinThisWeek){

        // This occurrence will become the main one. 
        // It gets displayed on the schedule.
        let occurrence = this.occurrencesWithinThisWeek[o1]

        // X('getting overlapping occurrences: ' + occurrence.time.id)

        // Loop over all occurrencesWithinFiveWeekSpan
        for (let o2 in this.occurrencesWithinFiveWeekSpan){

          const o5w = this.occurrencesWithinFiveWeekSpan[o2]

          // If it is a different time, but the same start time and weekday, 
          // then add it to the group array.
          if(
            occurrence.time.id != o5w.time.id
            && occurrence.startTimeString == o5w.startTimeString
            && occurrence.weekdayNum == o5w.weekdayNum
          ){
            occurrence.group.push(o5w)
          }
        }
        
        this.occurrencesGrouped.push(occurrence)

        // X('got overlapping occurrences: ' + occurrence.time.id)

      }

      return this.occurrencesGrouped

    }

  },
  async created () {
    
    X('schedule!')

    await this.fetchData()

    // Build occurrences from times.
    this.processTimes(this.times)
    
    // Group the overlapping occurrences.
    this.groupOverlappingOccurrences()

    this.isScheduleReadyToRender = true

  },
}

</script>
