export default {
  schedule:{
    weekdays: {
      0: "Sunday",
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday"
    },
    weekdaysAbbreviated: {
      0: "Sun",
      1: "Mon",
      2: "Tues",
      3: "Wed",
      4: "Thurs",
      5: "Fri",
      6: "Sat",
    },
    all: "All Shows",
    calendar: "Calendar",
    find: "Find",
  },
  shows: {
    backToShows: "Back to Schedule"
  }
}